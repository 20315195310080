import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ROOT_FOLDER } from '../../hooks/useFolder'

export default function FolderBreadCrumbs({ currentFolder }) {
    let path = currentFolder === ROOT_FOLDER ? [] : [ROOT_FOLDER]
    if (currentFolder) path = [...path, ...currentFolder.path]

    return (
        <Breadcrumb className="flex-grow-1" listProps={{ className: "bg-white p-0 m-0"}}>
            {
                path.map((folder, index) => (
                    <Breadcrumb.Item linkAs={Link} linkProps={{
                        to: {
                            pathname: folder._id ? `/folder/${folder._id}` : '/',
                            state: {
                                folder: {
                                    ...folder,
                                    path: path.slice(1, index)
                                }
                            }
                        }
                    }} key={ folder._id } className="text-truncate d-inline-block" style={{ maxWidth: '100px' }} >{ folder.name }</Breadcrumb.Item>
                ))
            }
            <Breadcrumb.Item className="text-truncate d-inline-block" style={{ maxWidth: '200px' }} active>{ currentFolder.name }</Breadcrumb.Item>
        </Breadcrumb>
    )
}
