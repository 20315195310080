import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import { Breadcrumb, Container } from 'react-bootstrap'
import AddUserButton from './AddUserButton'
import Benutzer from './Benutzer'
import { useApi } from '../../mongo'

export default function Users() {
    const { getUsers } = useApi()
    const [ users, setUsers ] = useState()
    const [ loading, setLoading ] = useState(true)

    useEffect(() => {
        setLoading(true)
        getUsers().then(u => {
            setUsers(u.data)
            setLoading(false)
        })
    }, [])

    return (
        <>
            <Navbar />
            <Container fluid>
                <div className="d-flex align-items-center">
                    <Breadcrumb className="flex-grow-1" listProps={{ className: "bg-white p-0 m-0"}}>
                        <Breadcrumb.Item active>Benutzer</Breadcrumb.Item>
                    </Breadcrumb>
                    <AddUserButton />
                </div>
            </Container>

            <div className="d-flex flex-wrap">
            {
                !loading && 
                users.map(u => (
                    <div key={u._id} style={{ maxWidth: '250px'}} className="p-2">
                        <Benutzer user={u} />
                    </div>
                ))
            }
            </div>
        </>
    )
}
