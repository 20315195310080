import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../../contexts/AuthContext'
import { Button, Modal, ProgressBar, Toast } from 'react-bootstrap'
import { useApi } from '../../mongo'

const mime = require('mime-types')
const FileDownload = require('js-file-download')

export default function File({ file }) {

    const { currentUser } = useAuth()
    const { isAdmin } = currentUser.rights

    const [ showContextMenu, setShowContextMenu ] = useState(false)
    const [ showFileInfo, setShowFileInfo ] = useState(false)
    const [ pos, setPos ] = useState({ pageX: 0, pageY: 0 })

    const btnRef = useRef()

    const { downloadFile, deleteFile } = useApi()
    const [downloadedFile, setDownloadedFile] = useState({ error: false, progress: 0, show: false })

    const handleContext = e => {
        if (!isAdmin || e.target != btnRef.current) return

        e.preventDefault()

        setPos({ pageX: e.pageX, pageY: e.pageY })
        setShowContextMenu(true)
    }

    const handleClick = e => {
        if (!isAdmin) return;

        setShowContextMenu(false)
    }

    useEffect(() => {
        document.addEventListener('contextmenu', handleContext)
        document.addEventListener('click', handleClick)
        return () =>  {
            document.removeEventListener('contextmenu', handleContext)
            document.removeEventListener('click', handleClick)
        }
    }, [])

    const downloadFileClick = () => {
        setDownloadedFile({...downloadFile, show: true})
        downloadFile(file, progress).then(
            f => { 
                const ext = mime.extension(f.headers['content-type'])
                FileDownload(f.data, ext ? `${file.name}.${ext}` : file.name)

                setTimeout(() => setDownloadedFile({...downloadedFile, show: false}), 1500)
            },
            err => { setDownloadedFile({...downloadedFile, error: true}) }
        )
    }

    const progress = (progressEvent) => {
        setDownloadedFile({...downloadFile, progress: Math.round((parseFloat(progressEvent.loaded) / parseFloat(progressEvent.total)) * 100)})
    }

    const removeFile = e => {
        e.stopPropagation()
        setShowContextMenu(false);

        if (!window.confirm('Datei löschen?')) return

        deleteFile(file).then(() => window.location.href = window.location.href)
    }

    const fileInfo = e => {
        e.stopPropagation()

        console.log('fileInfo')
        setShowFileInfo(true)
    }

    return (
        <>
            <Button variant="outline-dark" onClick={downloadFileClick} ref={btnRef}>
                <FontAwesomeIcon icon={faFile} style={{ marginRight: '.5rem' }} />
                {file.name}
            </Button>
            {
                showContextMenu &&
                ReactDOM.createPortal(
                    <div style={{
                        position: 'absolute',
                        left: pos.pageX,
                        top: pos.pageY,
                        maxWidth: '250px',
                        display: 'block',
                        boxShadow: '5px 5px 20px 2px rgba(221,221,221,0.8)',
                        border: '1px solid rgba(0,0,0, .2)'
                    }}>
                        <div className="d-flex flex-wrap flex-column bg-white">
                            <Button onClick={fileInfo} variant="outline-dark" size="sm" style={{ borderRadius: 0, border: 0 }}>Eigenschaften</Button>
                            <Button onClick={removeFile} variant="outline-danger" size="sm" style={{ borderRadius: 0, border: 0 }}>Löschen</Button>
                        </div>
                        
                    </div>,
                    document.body
                )
            }

            <Modal show={showFileInfo} onHide={() => setShowFileInfo(false)}>
                <Modal.Header>{ file.name }</Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-3">Speicherort</div>
                        <div className="col">{ file.file.path }</div>
                    </div>
                    <div className="row">
                        <div className="col-3">Größe</div>
                        <div className="col">{ `~${Math.round(file.file.size / 1024)} kB`}</div>
                    </div>
                    <div className="row">
                        <div className="col-3">Dateityp</div>
                        <div className="col">{ file.file.mimetype }</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowFileInfo(false)}>Schließen</Button>
                </Modal.Footer>
            </Modal>

            {
                
                ReactDOM.createPortal(
                    <div style={{
                        position: 'absolute',
                        right: '1rem',
                        bottom: '1rem',
                        maxWidth: '250px'
                    }}>
                        <Toast show={downloadedFile.show}>
                            <Toast.Header className="text-truncate w-100 d-flex" closeButton={false}>
                                    <strong className="flex-grow-1">{ file.name }</strong>
                                    <button className="bg-transparent" style={{ border: 0}} onClick={() => setDownloadedFile({...downloadedFile, show: false})}>
                                        <span style={{
                                            fontSize: '1.5rem',
                                            fontWeight: '700',
                                            color: '#000',
                                            textShadow: '0 1px 0 #fff'
                                        }}>
                                            ×
                                        </span>
                                    </button>
                            </Toast.Header>
                            <Toast.Body>
                                <ProgressBar
                                    animated={ !downloadedFile.error }
                                    now={ downloadedFile.error ? 100 : downloadedFile.progress }
                                    variant={ downloadedFile.error ? 'danger' : 'success' }
                                    label={ downloadFile.error ? 'Error' : `${downloadedFile.progress}%` } />
                            </Toast.Body>
                        </Toast>
                    </div>,
                    document.body
                )
            }
        </>
    )
}
