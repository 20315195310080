import React, { useRef, useState, useEffect } from 'react'
import { Form, Button, Card, Container, Alert } from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext'
import { useHistory, useLocation } from 'react-router-dom'

import Navbar from '../drive/Navbar'

export default function Login() {
    const userRef = useRef();
    const passRef = useRef();
    const history = useHistory();
    const { state } = useLocation()

    const { currentUser, login } = useAuth();

    const [error, setError] = useState()

    const handleSubmit = (e) => {
        e.preventDefault();

        setError(null)
        
        if (login(userRef.current.value, passRef.current.value)) {
            history.push(state?.path ?? '/')
        }
        else {
            setError("Fehler bei der Anmeldung");
        }
    }

    useEffect(() => {
        if (currentUser) history.push(state?.path ?? '/')
    }, [currentUser])

    return (
        <>
            <Navbar />
            <Container className="d-flex align-items-center justify-content-center" style={{ maxWidth: '400px' }}>
                <Card>
                    <Card.Body>
                        <h2 className="text-center mb-4">Anmeldung</h2>
                        {
                            error &&
                            <Alert variant="danger">{ error }</Alert>
                        }
                        <Form onSubmit={handleSubmit}>
                            <Form.Group id="user">
                                <Form.Label>Benutzername</Form.Label>
                                <Form.Control type="text" ref={ userRef } required />
                            </Form.Group>
                            <Form.Group id="pass">
                                <Form.Label>Passwort</Form.Label>
                                <Form.Control type="password" ref={ passRef } required />
                            </Form.Group>
                            <Button type="submit" className="w-100 mt-2">Anmelden</Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}
