import axios from 'axios'

export const api_login = (user, pass) => {
    return axios.post(`${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api/users/login`, { name: user, pass: pass })
}

const api_create_folder = (user, foldername, parentfolder, path) => {
    return axios.post(
        `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api/folders`, 
        {
            user: user._id,
            name: foldername,
            parent: parentfolder,
            path: path
        },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('jwt_downloadcenter')}`}}
    )
}

const api_get_folder = (folderid) => {
    return axios.get(
        `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api/folders/${folderid}`, 
        {
            headers: { 
                 Authorization: `Bearer ${sessionStorage.getItem('jwt_downloadcenter')}`
            }
        }
    )
}

const api_get_child_folders = (folderid) => {
    return axios.get(
        `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api/folders/${folderid}/children`, 
        { 
            headers: { 
                Authorization: `Bearer ${sessionStorage.getItem('jwt_downloadcenter')}`
            }
        }
    )
}

const api_get_root_folders = (user) => {
    return axios.get(
        `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api/users/${user._id}/folders`, 
        { 
            headers: { 
                Authorization: `Bearer ${sessionStorage.getItem('jwt_downloadcenter')}`
            }
        }
    )
}

const api_get_child_files = (folderid) => {
    return axios.get(
        `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api/folders/${folderid}/files`, 
        { 
            headers: { 
                Authorization: `Bearer ${sessionStorage.getItem('jwt_downloadcenter')}`
            }
        }
    )
}

const api_get_root_files = (user) => {
    return axios.get(
        `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api/users/${user._id}/files`, 
        { 
            headers: { 
                Authorization: `Bearer ${sessionStorage.getItem('jwt_downloadcenter')}`
            }
        }
    )
}


const api_save_file = (name, currentUser, currentFolder, file) => {
    const fd = new FormData();
    fd.append('name', name)
    fd.append('user', currentUser._id)
    fd.append('folder', currentFolder ? currentFolder._id : null)
    fd.append('file', file)

    return axios.put(
        `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api/files/upload`, 
        fd, 
        { 
            headers: { 
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${sessionStorage.getItem('jwt_downloadcenter')}`
            } 
        }
    );
}

const api_download_file = (file, callback) => {
    return axios({
        url: `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api/files/${file._id}/data`,
        method: 'GET',
        encoding: null,
        responseType: 'blob',
        onDownloadProgress: callback,
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('jwt_downloadcenter')}`
        }
    })
}

const api_delete_file = (file) => {
    return axios.delete(
        `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api/files/${file._id}`, 
        { 
            headers: { 
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${sessionStorage.getItem('jwt_downloadcenter')}`
            } 
        }
    );
}

const api_get_users = () => {
    return axios.get(
        `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api/users`, 
        { 
            headers: { 
                Authorization: `Bearer ${sessionStorage.getItem('jwt_downloadcenter')}`
            }
        }
    )
}

const api_save_user = (user) => {
    return axios.post(
        `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api/users`,
        user,
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('jwt_downloadcenter')}`
            }
        }
    )
}

const api_delete_user = (user) => {
    return axios.delete(
        `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api/users/${user._id}`,
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('jwt_downloadcenter')}`
            }
        }
    )
}

const api_get_roles = () => {
    return axios.get(
        `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api/roles`,
        {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('jwt_downloadcenter')}`
            }
        }
    )
}

export function useApi() {
    return {
        createFolder: api_create_folder,
        getFolder: api_get_folder,
        getChildFolders: api_get_child_folders,
        getRootFolders: api_get_root_folders,
        saveFile: api_save_file,
        downloadFile: api_download_file,
        deleteFile: api_delete_file,
        getChildFiles: api_get_child_files,
        getRootFiles: api_get_root_files,
        getUsers: api_get_users,
        saveUser: api_save_user,
        deleteUser: api_delete_user,
        getRoles: api_get_roles
    }
}