import React, { useContext, useState, useEffect } from 'react'
import { api_login } from '../mongo'


const AuthContext = React.createContext()

export function useAuth() { 
    return useContext(AuthContext) 
}

export function AuthProvider({ children }) {

    const [currentUser, setCurrentUser] = useState()
    const logout = () => {
        setCurrentUser(null)
        sessionStorage.removeItem('session_downloadcenter')
        sessionStorage.removeItem('jwt_downloadcenter')
    }

    useEffect(() => {
        let user = sessionStorage.getItem('session_downloadcenter');
        if (user) {
            setCurrentUser(JSON.parse(user));
        }
    }, [])

    var login = (user, pass) => {
        return api_login(user, pass).then(res => {
            if (!res.data.user) return false;

            let { name, _id, rootFolder } = res.data.user;

            setCurrentUser({ name: name, _id: _id, rootFolder: rootFolder, rights: res.data.rights })
            sessionStorage.setItem('session_downloadcenter', JSON.stringify({ name: name, _id: _id, rootFolder: rootFolder, rights: res.data.rights }))
            sessionStorage.setItem('jwt_downloadcenter', res.data.jwt)
            return true;
        })
    }

    const value = {
        currentUser,
        login,
        logout
    }

    return (
        <AuthContext.Provider value={ value }>
            { children }
        </AuthContext.Provider>
    )
}
