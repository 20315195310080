import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder } from '@fortawesome/free-solid-svg-icons'

export default function Folder({ folder }) {
    return (
        <Button as={Link} to={{
            pathname: `/folder/${folder._id}`,
            state: { folder: folder }
        }} variant="outline-secondary" className="text-truncate w-100">
            <FontAwesomeIcon icon={faFolder} style={{ marginRight: '.5rem' }} />
            {folder.name}
        </Button>
    )
}
