import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileUpload } from '@fortawesome/free-solid-svg-icons'

import { useAuth } from '../../contexts/AuthContext'
import { useApi } from '../../mongo'
import { Form, Button, Modal } from 'react-bootstrap'

export default function AddFileButton({ currentFolder }) {

    const { currentUser } = useAuth()
    const { saveFile, getUsers } = useApi()

    const [ loading, setLoading ] = useState(true)
    const [ users, setUsers ] = useState()
    const [ selectedUser, setSelectedUser ] = useState(currentUser._id)

    const fileRef = useRef()
    const nameRef = useRef()

    useEffect(() => {
        setLoading(true)
        getUsers().then(u => {
            setUsers(u.data)
            setLoading(false)
        })
    }, [])

    const [open, setOpen] = useState(false)
    const openModal = () => setOpen(true)
    const closeModal = () => setOpen(false)

    const handleSubmit = (e) => {
        e.preventDefault()

        const file = fileRef.current.files[0]
        if (currentFolder === null || file === null) return

        console.log(currentFolder)
        saveFile(nameRef.current.value, { _id: selectedUser }, currentFolder, file)
            .then(r => window.location.href=window.location.href)
    }

    return (
        <>
            {
                !loading &&
                <>
                <Button variant="outline-success" size="sm" className="mx-2" style={{ minWidth: '35px' }} onClick={openModal}>
                    <FontAwesomeIcon icon={ faFileUpload } />
                </Button>

                <Modal show={ open } onHide={ closeModal }>
                    <Form onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>Dateiname</Form.Label>
                                <Form.Control required ref={nameRef}/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Datei</Form.Label>
                                <Form.File required ref={fileRef} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Benutzer</Form.Label>
                                <Form.Control as="select" onChange={e => setSelectedUser(e.target.value)}>
                                    {
                                        users.map(u => (
                                            <option key={u._id} value={u._id}>{ u.name }</option>
                                            ))
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={closeModal}>Schließen</Button>
                            <Button variant="success" type="submit">Speichern</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
                </>
            }
        </>
    )
}
