import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, Alert } from 'react-bootstrap'
import { useApi } from '../../mongo'

export default function AddUserButton() {
    const { saveUser, getRoles } = useApi()

    const [ open, setOpen ] = useState(false)
    const openModal = () => setOpen(true)
    const closeModal = () => setOpen(false)

    const [username, setUsername] = useState('')
    const [pass, setPass] = useState('')
    const [passRep, setPassRep] = useState('')
    const [selectedRole, setRole] = useState('')

    const [error, setError] = useState([])

    const [ loading, setLoading ] = useState(true)
    const [ roles, setRoles ] = useState([]) 

    useEffect(() => {
        setLoading(true)
        getRoles().then(r => {
            setRoles(r.data)
            setLoading(false)
        })
    }, [])

    const handleSubmit = e => {
        e.preventDefault();

        let errors = []

        if (username.length === 0)
            errors = [...errors, 'Bitte Benutzernamen eingeben'];

        if (!pass || !passRep) 
            errors = [...errors, 'Bitte Passwörter eingeben']

        if (pass !== passRep) 
            errors = [...errors, 'Die Passwörter stimmen nicht überein']

        if (errors.length) {
            setError(errors)
            return
        }

        saveUser({
            name: username,
            pass: pass,
            role: selectedRole
        }).then(() => window.location.href = window.location.href)
    }

    return (
        <>
        <Button variant="outline-success" size="sm" style={{ maxWidth: '35px' }} onClick={openModal}>
            <FontAwesomeIcon icon={faUserPlus} />
        </Button>

        {
            !loading && 
            <Modal show={open} onHide={closeModal}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        {
                            error.length > 0 &&
                            <Alert variant="danger" className="d-flex flex-wrap flex-column">
                                {error.map((e,i) => <p className="p-0 m-0" key={i}>{e}</p>)}
                            </Alert>
                        }
                        <Form.Group>
                            <Form.Label>Benutzername</Form.Label>
                            <Form.Control value={username} onChange={e => setUsername(e.target.value)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Rolle</Form.Label>
                            <Form.Control as="select" value={selectedRole} onChange={e => setRole(e.target.value)}>
                                {
                                    roles.map(r => (
                                        <option key={r._id} value={r._id}>{r.name}</option>
                                    ))
                                }
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Passwort</Form.Label>
                            <Form.Control value={pass} onChange={e => setPass(e.target.value)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Passwort wiederholen</Form.Label>
                            <Form.Control value={passRep} onChange={e => setPassRep(e.target.value)} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>Schließen</Button>
                        <Button variant="success" type="submit">Speichern</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        }
        </>
    )
}
