import React, { useState, useEffect, useRef } from 'react'
import { Button, Form, Modal, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { useApi } from '../../mongo'

export default function Benutzer({ user }) {
    const [open, setOpen] = useState(false)
    const closeModal = () => setOpen(false)
    const openModal = () => setOpen(true)

    const pwRef = useRef()
    const pwRefRep = useRef()

    const [error, setError] = useState()

    const [loading, setLoading] = useState(true)
    const [roles, setRoles] = useState()
    
    const { getRoles, saveUser, deleteUser } = useApi()

    useEffect(() => {
        setLoading(true)
        getRoles().then(r => {
            setRoles(r.data)
            setLoading(false)
        })
    }, [])

    const [selectedRole, setSelectedRole] = useState(user.role)

    const removeUser = () => {
        if (window.confirm('Benutzer löschen?')) {
            deleteUser(user).then(() => window.location.href = window.location.href)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (pwRef.current.value !== '') {
            if (pwRef.current.value !== pwRefRep.current.value) {
                setError('Passwörter stimmen nicht überein')
                return
            }
            
            user.pass = pwRef.current.value
        }

        if (user.role !== selectedRole)
            user.role = selectedRole

        saveUser(user).then(() => {
            closeModal()
        })

    }

    let content = null;
    if (!loading)
        content = (
        <>
            <Button variant="outline-secondary" className="text-truncate w-100" onClick={openModal}>
                <FontAwesomeIcon icon={faUser} style={{ marginRight: '.5rem' }} />
                {user.name}
            </Button>

            <Modal show={open} onHide={closeModal}>
                <Modal.Header>
                    <h4>{ user.name }</h4>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                            <Form.Group>
                                <Form.Label className="d-inline-block">Passwort</Form.Label>
                                <Form.Control className="mx-2 form-control-plaintext d-inline-block w-auto" value={user.pass} />
                            </Form.Group>

                            <hr />

                            <Form.Group className="mt-2">
                                <Form.Label>Rolle</Form.Label>
                                <Form.Control as="select" className="bg-white" value={selectedRole} onChange={e => setSelectedRole(e.target.value)}>
                                    {
                                        roles.map(r => (
                                            <option key={r._id} value={r._id}>{r.name}</option>
                                        ))
                                    }
                                </Form.Control>
                            </Form.Group>

                            {
                                error &&
                                <Alert variant="danger">
                                    {error}
                                </Alert>
                            }

                            <Form.Group>
                                <Form.Label>Neues Passwort</Form.Label>
                                <Form.Control ref={pwRef} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Passwort wiederholen</Form.Label>
                                <Form.Control ref={pwRefRep} />
                            </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={removeUser}>Löschen</Button>
                        <Button type="submit" variant="success">Speichern</Button>
                        <Button variant="secondary" onClick={closeModal}>Schließen</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )

    return content
}
