import React from 'react'
import { Container } from 'react-bootstrap'
import { useFolder } from '../../hooks/useFolder'
import { useParams, useLocation, Link } from 'react-router-dom'
import AddFolderButton from './AddFolderButton'
import AddFileButton from './AddFileButton'
import FolderBreadCrumbs from './FolderBreadCrumbs'
import Navbar from './Navbar'
import Folder from './folder'
import File from './File'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { useAuth } from '../../contexts/AuthContext'

export default function Dashboard() {
    const { state = {} } = useLocation();
    const { folderId } = useParams()
    const { folder, childFolders, childFiles } = useFolder(folderId, state.folder)
    const { currentUser } = useAuth()

    return (
        <>
            <Navbar />
            <Container fluid>
                <div className="d-flex align-items-center">
                    <FolderBreadCrumbs currentFolder={ folder } />

                    {
                        currentUser.rights.canAddUsers &&
                        <Link to='/users' className="btn btn-outline-success btn-sm mr-2">
                            <FontAwesomeIcon icon={faUsers} />
                        </Link>
                    }

                    { 
                        currentUser.rights.canAddFiles &&
                        <AddFileButton currentFolder={ folder }/>
                    }

                    {
                        currentUser.rights.canAddFolders &&
                        <AddFolderButton currentFolder={ folder }/>
                    }
                </div>
                {
                    childFolders.length > 0 &&
                    (
                        <div className="d-flex flex-wrap">
                            {
                                childFolders.map(cFolder => (
                                    <div key={cFolder._id} style={{ maxWidth: '250px'}} className="p-2">
                                        <Folder folder={cFolder} />
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
                {
                    childFolders.length > 0 && childFiles.length > 0 && <hr />
                }
                {
                    childFiles.length > 0 && 
                    (    
                        <div className="d-flex flex-wrap">
                            {
                                childFiles.map(cFile => (
                                    <div key={cFile._id} style={{ maxWidth: '250px' }} className="p-2">
                                        <File folder={folder} file={cFile} />
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
            </Container>
        </>
    )
}
