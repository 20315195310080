import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import logo from '../../assets/img/Logo.png'

import { useAuth } from '../../contexts/AuthContext'

export default function NavbarComponent() {

    const { currentUser } = useAuth();

    return (
        <Navbar bg="light" expand="true" style={{ paddingLeft: ".5rem", paddingRight: ".5rem" }} className="mb-4">
            <Navbar.Brand as={Link} to="/">
                <img src={logo} height="50"  alt="logo" /> ESD Drive
            </Navbar.Brand>

            {
                currentUser &&
                <div className="d-flex flex-wrap flex-column align-items-end">
                    <label style={{ fontWeight: '600', color:'#555'}}>{ currentUser.name }</label>
                    <Nav as={Link} to="/logout" style={{ fontSize: '.8rem'}}>
                        Logout
                    </Nav>
                </div>
            }
        </Navbar>
    )
}
