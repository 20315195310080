import React from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'

export default function PrivateRoute({ component: Component, ...rest }) {
    const { currentUser } = useAuth()
    const { pathname } = useLocation()

    return (
        <Route {...rest} render={ props => {
            return currentUser ? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { path: pathname } }} />
        }} />
    )
}
