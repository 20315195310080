import React, { useState, useEffect } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons'

import { useAuth } from '../../contexts/AuthContext'
import { useApi } from '../../mongo'
import { ROOT_FOLDER } from '../../hooks/useFolder'

export default function AddFolderButton({ currentFolder }) {

    const { currentUser } = useAuth()
    const { createFolder, getUsers } = useApi()
    
    const [ loading, setLoading ] = useState(true)
    const [ users, setUsers ] = useState()

    const [ selectedUser, setSelectedUser ] = useState(currentUser._id)

    useEffect(() => {
        setLoading(true)
        getUsers().then(u => {
            setUsers(u.data)
            setLoading(false)
        })
    }, [])

    const [name, setName] = useState('')
    
    const path = [...currentFolder.path]
    if (currentFolder !== ROOT_FOLDER) {
        path.push({ name: currentFolder.name, _id: currentFolder._id })
    }

    const [open, setOpen] = useState(false)
    const openModal = () => {
        setOpen(true)
    }

    const closeModal = () => {
        setOpen(false)
        setName('')
    }

    const handleSubmit = e => {
        e.preventDefault();

        if (currentFolder === null) return

        createFolder({ _id: selectedUser }, name, currentFolder._id, path).then(() => {
            closeModal();
            window.location.href=window.location.href
        });
        closeModal()
    }

    return (
        <>
            {
                !loading &&
                <>
                    <Button variant="outline-success" size="sm" onClick={openModal} style={{ minWidth: '35px' }}>
                        <FontAwesomeIcon icon={ faFolderPlus } />
                    </Button>

                    <Modal show={ open } onHide={ closeModal }>
                        <Form onSubmit={handleSubmit}>
                            <Modal.Body>
                                <Form.Group>
                                    <Form.Label>Ordnername</Form.Label>
                                    <Form.Control type="text" required value={name} onChange={e => setName(e.target.value)} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Benutzer</Form.Label>
                                    <Form.Control as="select" onChange={e => setSelectedUser(e.target.value)}>
                                        {
                                            users.map(u => (
                                                <option key={u._id} value={u._id}>{ u.name }</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={closeModal}>Schließen</Button>
                                <Button variant="success" type="submit">Speichern</Button>
                            </Modal.Footer>
                        </Form>
                    </Modal>
                </>
            }
        </>
    )
}
