import { useReducer, useEffect } from 'react'
import { useApi } from '../mongo'
import { useAuth } from '../contexts/AuthContext'

const ACTIONS = {
    SELECT_FOLDER: 'select-folder',
    UPDATE_FOLDER: 'update-folder',
    UPDATE_CHILD_FOLDERS: 'update-child-folders',
    UPDATE_CHILD_FILES: 'update-child-files'
}

export const ROOT_FOLDER = {
    name: "Start",
    _id: null,
    path: []
}

function reducer(state, {type, payload}) {
    switch(type) {
        case ACTIONS.SELECT_FOLDER:
            return {
                folderId: payload.folderId,
                folder: payload.folder,
                childFiles: [],
                childFolders: []
            }

        case ACTIONS.UPDATE_FOLDER:
            return {
                ...state,
                folder: payload.folder
            }

        case ACTIONS.UPDATE_CHILD_FOLDERS:
            return {
                ...state,
                childFolders: payload.childFolders
            }

        case ACTIONS.UPDATE_CHILD_FILES:
            return {
                ...state,
                childFiles: payload.childFiles
            }

        default:
            return state
    }
}

export function useFolder(folderId = null, folder = ROOT_FOLDER) {
    const [state, dispatch] = useReducer(reducer, {
        folderId,
        folder,
        childFolders: [],
        childFiles: [],
        path: []
    });

    const { getFolder, getChildFolders, getRootFolders, getChildFiles, getRootFiles } = useApi();
    const { currentUser } = useAuth()

    useEffect(() => {
        dispatch({ type: ACTIONS.SELECT_FOLDER, payload: { folderId, folder } })
    }, [folderId, folder])

    useEffect(() => {
        if (folderId === null || folderId === undefined) {
            return dispatch({
                type: ACTIONS.UPDATE_FOLDER,
                payload: { folder: ROOT_FOLDER }
            })
        }

        getFolder(folderId)
            .then(folder => {
                dispatch({
                    type: ACTIONS.UPDATE_FOLDER,
                    payload: { folder: {...folder.data[0]} }
                })
            })
            .catch(() => {
                dispatch({
                    type: ACTIONS.UPDATE_FOLDER,
                    payload: { folder: ROOT_FOLDER }
                })
            })

    }, [folderId])

    useEffect(() => {
        if (folderId === null || folderId === undefined) {
            return getRootFolders(currentUser).then(folders => {
                const root = folders.data.filter(f => f.parent === null || !f.hasOwnProperty('parent'))

                dispatch({
                    type: ACTIONS.UPDATE_CHILD_FOLDERS,
                    payload: { childFolders: root }
                })
            })
        }

        getChildFolders(folderId).then(children => {
            dispatch({
                type: ACTIONS.UPDATE_CHILD_FOLDERS,
                payload: { childFolders: children.data }
            })
        })
    }, [folderId])

    useEffect(() => {
        if (folderId === null || folderId === undefined) {
            return getRootFiles(currentUser).then(files => {
                const root = files.data.filter(f => f.folder === null)

                dispatch({
                    type: ACTIONS.UPDATE_CHILD_FILES,
                    payload: { childFiles: root }
                })
            })
        }

        getChildFiles(folderId).then(children => {
            dispatch({
                type: ACTIONS.UPDATE_CHILD_FILES,
                payload: { childFiles: children.data }
            })
        })
    }, [folderId])

    return state;
}